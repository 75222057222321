import { Datagrid, DateField, FunctionField, List, ListContextProvider, NumberField, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";

export const DataMappingsList = () =>{
    return (
        <List exporter={false} title='Mappings for Mindbox model (JUST.NET)'>
            <Datagrid bulkActionButtons={false} rowClick='edit'>
                <TextField source='Name' />
            </Datagrid>
        </List>
    );
}