import { BooleanInput, Edit, FormDataConsumer, maxValue, minValue, NumberInput, required, SimpleForm, TextInput } from "react-admin";
import {JsonInput } from "react-admin-json-view";

export const DataMappingsEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <JsonInput
                    source="Json"
                    validate={[required()]}
                    jsonString={true} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                        // Props passed to react-json-view
                        name: null,
                        collapsed: true,
                        enableClipboard: false,
                        displayDataTypes: false,
                    }}
                />

            </SimpleForm>
        </Edit>
    );
}