import { ArrayField, Datagrid, DateField, DateTimeInput, Edit, SaveButton, SelectInput, SimpleForm, TextField, TextInput } from "react-admin";
import { Grid } from "@mui/material";
import { SmppStatuses } from "../../models/smppStatuses";


export const IntegritylogsShow = () => {
    return (
        <Edit>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextInput source="SpiceId" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="RequestsNumber" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source='CreateDate' disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source='LastRequestDate' disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <ArrayField source="IntegrityLogItems">
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="Name" />
                                <DateField source="DateTime"  showTime={true} />
                            </Datagrid>
                        </ArrayField>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}