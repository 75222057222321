export const cp = {
    cp:{
        menuGroups:{
            labels:{
                mts_tickets: { 
                    en: 'Tickets' 
                },
                mts_rules: { 
                    en: 'Rules' 
                },
                mts_elk_alerts: { 
                    en: 'ELK alerts' 
                },

                qk_run_export: { 
                    en: 'Mindbox export runs' 
                },
                qk_download_files: { 
                    en: 'Download/Parce files' 
                },
                qk_send_to_ucrm: { 
                    en: 'Sendings to UCRM' 
                },

                qk_send_to_mindbox: { 
                    en: 'Sendings to Mindbox' 
                },
                audit_api: { 
                    en: 'API Audit' 
                },

                qk_datasync_sync_consumer: { 
                    en: 'Synchronization of consumers' 
                },
                qk_datasync_sync_consumercommunicationsubscription: { 
                    en: 'Synchronization of consumers communications subscription' 
                },
                qk_datasync_sync_consumertasksteps: { 
                    en: 'Synchronization of consumer task steps' 
                },
                qk_datasync_sync_consumerfirstacquisition: { 
                    en: 'Synchronization of consumers first acquisition' 
                },
                qk_datasync_sync_person: { 
                    en: 'Synchronization of person' 
                },
                qk_datasync_sync_rrpmarketingmessagecontact: { 
                    en: 'Synchronization of RRP Marketing Message Contact' 
                },
                qk_datasync_sync_consumerproductinspectionrequests: { 
                    en: 'Synchronization of consumer prod requests' 
                },
                qk_datasync_sync_product: { 
                    en: 'Synchronization of product' 
                },
                qk_datasync_sync_order: { 
                    en: 'Synchronization of order' 
                },
                qk_datasync_sync_lendingorder: { 
                    en: 'Synchronization of lending order' 
                },
                qk_datasync_sync_consumerfirstaccessorypurchase: { 
                    en: 'Synchronization of first accessory purchase' 
                },
                qk_datasync_sync_consumerfirstaccessorypurchasecancel: { 
                    en: 'Synchronization of cancel first accessory purchase' 
                },
                qk_datasync_sync_consumerfirstconsumablepurchase: { 
                    en: 'Synchronization of first consumable purchase' 
                },
                qk_datasync_sync_consumerfirstconsumablepurchasecancel: { 
                    en: 'Synchronization of cancel first consumable purchase' 
                },
                qk_datasync_sync_lastretailbrand: { 
                    en: 'Synchronization of lending order' 
                },
                qk_datasync_sync_lastretailbrandreference: { 
                    en: 'Synchronization of lending order' 
                },
                qk_datasync_sync_lastactivedevice: { 
                    en: 'Synchronization of last active devices' 
                },
                qk_datasync_sync_lastactivedevicereference: { 
                    en: 'Synchronization of last active device references' 
                },
                qk_datasync_sync_consumerallocations: { 
                    en: 'Synchronization of allocations' 
                },
                qk_datasync_sync_cleaningrequests: { 
                    en: 'Synchronization of cleaning requests' 
                },
                qk_datasync_sync_geographyconsumer: { 
                    en: 'Synchronization of geography consumer' 
                },
                qk_datasync_sync_productinspection: {
                    en: 'Syncronization of product inspections'
                },
                'allBrokenCount/allBrokenCount': { 
                    en: 'Broken data count table' 
                },
                'allBrokenCount/autoStatList': { 
                    en: 'Ticket autorisation statistics list' 
                },
                'allBrokenCount/smppStatList': { 
                    en: 'Smpp log statisticks list' 
                },
                datasync_mappings: { 
                    en: 'Mappings' 
                },
                smpplogs: { 
                    en: 'SMPP Logs' 
                },
                smppsettings: { 
                    en: 'SMPP Settings' 
                },
                smppperformance: { 
                    en: 'SMPP Performance' 
                },
                featureflags: { 
                    en: 'Feature Flags' 
                },
                qk_datasync_sync_equipmentproductinstance: { 
                    en: 'Synchronization of product instance status' 
                },
                dataintegritylogs: { 
                    en: 'Data Integrity Logs' 
                },
            }
        }
    }
}